import React, { useState } from "react";
import "../styles/App.css";
import Header from "./Header.js";
import BakeryList from "./BakeryList";
import ViewBakery from './ViewBakery'
import AddRating from './AddRating'

import { Switch, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

// for apollo client
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient, HttpLink, InMemoryCache } from "apollo-boost";
import { setContext } from "apollo-link-context";

import NewBakery from "./NewBakery";
import SecuredRoute from "./SecuredRoute";

import {apiUrl} from '../config'

function App() {
  // used state to get accessToken through getAccessTokenSilently(), the component re-renders when state changes, thus we have
  // our accessToken in apollo client instance.
  const [accessToken, setAccessToken] = useState("");

  const { getAccessTokenSilently, loading } = useAuth0();

  if (loading) {
    return "Loading...";
  }

  // get access token
  const getAccessToken = async () => {
    // getAccessTokenSilently() returns a promise
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    } catch (e) {
      console.log(e);
    }
  };
  getAccessToken();

  // for apollo client
  const httpLink = new HttpLink({
    uri: apiUrl,
  });

  const authLink = setContext((_, { headers }) => {
    const token = accessToken;
    if (token) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`
        }
      };
    } else {
      return {
        headers: {
          ...headers
        }
      };
    }
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    connectToDevTools: true
  });

  return (
    <ApolloProvider client={client}>
      <Header />
      <Switch>
        <Route exact path="/" component={BakeryList} />
        <SecuredRoute path={'/bakeries/:bakery_id/add-rating'} component={AddRating} />
        <Route path={'/bakeries/:id'} component={ViewBakery} />
        <SecuredRoute path="/new-bakery" component={NewBakery} />
      </Switch>
    </ApolloProvider>
  );
}

export default App;
