import React, { useState } from "react";
import "../styles/index.scss";

import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useAuth0 } from "@auth0/auth0-react";
import { withApollo } from "@apollo/react-hoc";
import { BAKERIES_LIST } from "./BakeryList";
import { useHistory } from "react-router-dom";

const SUBMIT_BAKERY = gql`
  mutation MyMutation(
    $name: String!
    $location: String!
    $lon: float8!
    $lat: float8!
  ) {
    insert_bakeries(
      objects: [{ name: $name, location: $location, lon: $lon, lat: $lat }]
    ) {
      affected_rows
    }
  }
`;

function NewBakery(props) {
  let history = useHistory();
  const { user } = useAuth0();
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");
  const [error, setError] = useState("");

  const [submitBakery] = useMutation(SUBMIT_BAKERY, {
    onCompleted: () => history.push("/"), // never gets called
  });

  return (
    <>
      <div className="new_bakery">
        <form
          className="bakery_form"
          onSubmit={(e) => {
            e.preventDefault();
            submitBakery({
              variables: { name, location, lat, lon },
              refetchQueries: [{ query: BAKERIES_LIST }],
            }).catch(function (error) {
              console.log(error);
              setError(error.toString());
            });
            //You are having a controlled component where input value is determined by this.state.city.
            // So once you submit you have to clear your state which will clear your input automatically.
            setName("");
            setLocation("");
            setLat("");
            setLon("");
          }}
        >
          <h1>Add a new bakery</h1>
          <div>
            <label>What's the name of the bakery?</label>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
            />
          </div>
          <div>
            <label>What's the address of the bakery?</label>
            <input
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              type="text"
            />
          </div>
          <label>
            <strong>Just put any numbers in the lat long for now</strong>
          </label>
          <div className="inline__latlng">
            <div>
              <label>Latitude</label>
              <input
                value={lat}
                onChange={(e) => setLat(e.target.value)}
                type="number"
              />
            </div>
            <div>
              <label>Longitude</label>
              <input
                value={lon}
                onChange={(e) => setLon(e.target.value)}
                type="number"
              />
            </div>
          </div>
          <div>
            <button type="submit" value="Submit" className="button">
              Add Bakery
            </button>
          </div>
        </form>
        {error}
      </div>
    </>
  );
}

export default withApollo(NewBakery);
