
import React from "react";
import { Link } from "react-router-dom";
import "../styles/index.scss";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { BAKERIES_LIST } from "./BakeryList";
import { useAuth0 } from "@auth0/auth0-react";
import {formatUpdateDescription} from '../lib/format'

export const BAKERY_INFO = gql`
  query($id: uuid!) {
    bakeries_by_pk(uid: $id) {
        lat
        location
        lon
        name
        uid
        user {
          email
          id
        }
        rates_aggregate {
            aggregate {
              count(columns: id)
              avg {
                score
              }
            }
          }
      }
      rates(where: {bakery_uid: {_eq: $id}}) {
        comment
        id
        image_url
        pastry_type
        score
        user {
            email
            username
          }
        created_at
      }
      rates_aggregate {
        aggregate {
          count
          avg {
            score
          }
        }
      }
  }
`;

function ViewBakery(props) {
    let bakeryId = props.id ? props.id : props.match.params.id;
    
    const { isAuthenticated, user } = useAuth0();
    const { loading, error, data } = useQuery(BAKERY_INFO, {
        variables: { id: bakeryId }
      });

    let loggedUserId = "";
    if (isAuthenticated) {
      loggedUserId = user.sub;
    }

    if (loading) return "";
    if (error) return `Error! ${error.message}`;
    
    const bakery = data.bakeries_by_pk
    
    return (
        <>
      <div className="card" key={props.index}>
        {/* key is just a react thing, you can read it here : https://reactjs.org/docs/lists-and-keys.html#keys */}
        
            <div className="card__thumbnail">
                <img src="https://source.unsplash.com/featured/?bakery" />
            </div>
          <div className="bakery__details">            
              <h2 className="bakery_name">{bakery.name}</h2>
              <p className="location">{bakery.location}</p>
          </div>
          <div className="card__actions">
              <div>
                Pain au Chocolat, Croissant
                <div className="rating">
                    {bakery.rates_aggregate.aggregate.count} Reviews
                </div>
                <div className="score">
                    {bakery.rates_aggregate.aggregate.avg.score} Score
                </div>
              </div>
              <Link className="button" to={`/bakeries/${bakery.uid}/add-rating`}>Add Rating</Link>
          </div>
      </div>
      <div className="ratings">
        <h3>Reviews ({bakery.rates_aggregate.aggregate.count})</h3>
        {
            data.rates.map(r => {
                return (
                    <div className="rating_item" key={r.id}>
                        <div>Scored: <strong>{r.score}</strong></div>
                        <div>Ate: <strong>{r.pastry_type}</strong></div>
                        <div>Submitted by: {r.user.username}</div>
                        <div>{formatUpdateDescription(r.created_at)}</div>
                        <div className="review__images">
                            {!r.image_url ? null : <img src={r.image_url} />}
                        </div>
                    </div>
                )
            })
        }
      </div>
      </>
    );
  }
  
  export default ViewBakery;