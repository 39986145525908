import React from 'react';
import {Route} from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

function SecuredRoute(props) {
  const {component: Component, path, ...rest} = props;
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  return (
    <Route path={path} render={(routerProps) => {
        if (!isAuthenticated) {
          loginWithRedirect({});
          return <div></div>;
        }
        return <Component {...rest} {...routerProps} />
    }} />
  );
}

export default SecuredRoute;