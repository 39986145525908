
import React from "react";
import { Link } from "react-router-dom";
import "../styles/index.scss";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { BAKERIES_LIST } from "./BakeryList";
import { useAuth0 } from "@auth0/auth0-react";

function Bakery(props) {
    const { isAuthenticated, user } = useAuth0();
  
    let loggedUserId = "";
    if (isAuthenticated) {
      loggedUserId = user.sub;
    }
  
    // const postdate = new Date(props.bakery.created_at);
  
    // const [upvotePost] = useMutation(UPVOTE_POST, {
    //   variables: { postId: props.bakery.id, userId: loggedUserId },
    //   refetchQueries: [{ query: POSTS_LIST }]
    // });
  
    return (
      <div className="card" key={props.index}>
        {/* key is just a react thing, you can read it here : https://reactjs.org/docs/lists-and-keys.html#keys */}
        
            <div className="card__thumbnail">
                <Link to={`/bakeries/${props.bakery.uid}`}>
                <img src="https://source.unsplash.com/featured/?bakery" />
                </Link>
            </div>
          <div className="bakery__details">            
              <h2 className="bakery_name">{props.bakery.name}</h2>
              <p className="location">{props.bakery.location}</p>
          </div>
          <div className="card__actions">
              <div>
                Pain au Chocolat, Croissant
                <div className="rating">
                    {props.bakery.rates_aggregate.aggregate.count} Reviews
                </div>
                <div className="score">
                    {props.bakery.rates_aggregate.aggregate.avg.score} Score
                </div>
              </div>
            <Link className="button" to={`/bakeries/${props.bakery.uid}/add-rating`}>Add Rating</Link>
          </div>
        
      </div>
    );
  }
  
  export default Bakery;