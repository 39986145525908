import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { ReactComponent as PastryRatesLogo } from "../images/pastry-rates-logo.svg";

function Header() {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

  return (
    <>
      <div className="homepage-header">
        <h1 style={{ textAlign: "center" }}>
        <Link className="fw anchor" to="/">
          <PastryRatesLogo style={{ maxWidth: "200px" }} />
              </Link>
        </h1>
        <div className="page-nav" style={{ textAlign: "center", marginBottom: '0px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {!isAuthenticated && (
            <>
              <a
                className="auth-button button"
                onClick={() => loginWithRedirect({})}
              >
                Sign in to Rate
              </a>
            </>
          )}
          {isAuthenticated && (
            <>
              <Link className="anchor button" to="/new-bakery">
                Add Bakery
              </Link>
              <span> &nbsp;|&nbsp; </span>
              <span className="anchor">
                Ola{" "}
                <Link className="anchor" to={"/user/" + user.sub}>
                  {user.nickname}
                </Link>
              </span>
              <span> &nbsp;|&nbsp; </span>
              <a
                className="auth-button button logout-grey"
                onClick={() => logout()}
              >
                Log out
              </a>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default withRouter(Header);
