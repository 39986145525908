import React, { useState, useRef } from "react";
import "../styles/index.scss";
import axios from "axios";

import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useAuth0 } from "@auth0/auth0-react";
import { withApollo } from "@apollo/react-hoc";
import { BAKERIES_LIST } from "./BakeryList";
import { useHistory } from "react-router-dom";

import { Form, Field } from "react-final-form";
import Dropzone from "./Dropzone";

const SUBMIT_RATING = gql`
  mutation MyMutation(
    $pastry_type: String!
    $score: numeric!
    $comment: String
    $bakery_uid: uuid!,
    $image_url: String
  ) {
    insert_rates(
      objects: {
        pastry_type: $pastry_type
        score: $score
        comment: $comment
        bakery_uid: $bakery_uid
        image_url: $image_url
      }
    ) {
      affected_rows
    }
  }
`;

// An example of a custom range field component
function RangeInput(props) {
  return (
    <>
      <div>
        <label>What score out of 10 would you give it?</label>
      </div>
      <div>
        <input
          name="score"
          id="score"
          type="range"
          min="0"
          max="10"
          step="1"
          /*
           ** This special input
           ** object sets essential
           ** input props: value,
           ** onChange, onFocus etc.
           */
          {...props.input}
        />
      </div>
    </>
  );
}

function AddRating(props) {
  let history = useHistory();
  const { user } = useAuth0();
  const bakery_id = props.match.params.bakery_id;
  const [error, setError] = useState("");

  const [submitRating] = useMutation(SUBMIT_RATING, {
    onCompleted: () => history.push("/"), // never gets called
  });

  const getS3URL = async (file) => {
    try {
      const response = await axios.post(
        "https://quizzical-newton-bf1059.netlify.app/.netlify/functions/upload-files",
        JSON.stringify({
          fileName: file.name,
          fileType: file.type,
        })
      );
      return response.data.uploadURL;
    } catch (error) {
      setError("Sorry something went wrong with the image upload");
      console.log("err", error);
    }
  };

  const uploadImage = async (putURL, file) => {
    const options = {
      params: {
        Key: file.name,
        ContentType: file.type,
      },
      headers: {
        "Content-Type": file.type,
      },
    };

    try {
      const response = await axios.put(putURL, file, options);
      console.log(response);
    } catch (error) {
      setError("Sorry something - we won't be able to upload the image");
      console.log("err", error);
    }

    return {};
  };

  const submit = async (values) => {
    // e.preventDefault();
    // console.log(form.current)
    // const data = new FormData(form.current);
    // console.log(bakery_id,  data.get('pastry_type'));
    const vals = Object.assign({}, { bakery_uid: bakery_id }, values);
    console.log(vals);
    const s3url = await getS3URL(vals.image[0]);
    console.log(s3url);
    await uploadImage(s3url, vals.image[0]);
    vals['image_url'] = `https://pastry-rates.s3-eu-west-1.amazonaws.com/${vals.image[0].name}`
    delete vals['image']
    
    submitRating({
        variables: { ...vals },
        refetchQueries: [{ query: BAKERIES_LIST }],
      }).catch(function (error) {
        console.log(error);
        setError(error.toString());
      });
  };

  return (
    <>
      {error && <div className="form__error">{error}</div>}
      <Form
        onSubmit={submit}
        initialValues={{}}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} className="bakery_form">
            <h1>Add Rating</h1>
            <div>
              <label>What's type of pastry did you try?</label>
              <Field
                name="pastry_type"
                component="input"
                placeholder="Pain au Chocolat"
              />
            </div>
            <div>
              <Field name="score" component={RangeInput} />
            </div>
            <div>
              <label>Any other comments?</label>
              <Field name="comment" component="input" placeholder="..." />
            </div>
            <div>
              <label>Now let's add a sexy photo of that pastry!</label>
              <Field name="image">
                {(props) => (
                  <div>
                    <Dropzone {...props.input} />
                  </div>
                )}
              </Field>
            </div>

            <div>
              <button
                type="submit"
                value="Submit"
                className="button"
                disabled={submitting || pristine}
              >
                Add Rating
              </button>
            </div>
          </form>
        )}
      />
    </>
  );
}

export default withApollo(AddRating);
