'use strict';

module.exports = {
  appTitle: 'Pastry Rates',
  appShortTitle: 'PastryRates',
  appDescription: 'A place to keep track of the best pastries in your area.',
  baseUrl: 'http://localhost:3000',
  appPathname: '',
  apiUrl: 'https://pastry-rates-hasura.herokuapp.com/v1/graphql',
  auth0: {
    domain: "pastry-rates.eu.auth0.com",
    clientId: "7WcdZSDMOt58LhCOgbwiT2zHx5S6I010",
    redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT || "http://localhost:3000",
    audience: "https://pastry-rates-hasura.herokuapp.com/v1/graphql"
  }
};