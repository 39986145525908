import React from "react";
import "../styles/index.scss";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { withApollo } from "@apollo/react-hoc";
import Bakery from "./Bakery";

// post sorted in descending order by time of creation
export const BAKERIES_LIST = gql`
  {
    bakeries {
      name
      location
      uid
      lon
      lat
      user {
        email
        auth0_id
      }
      rates_aggregate {
        aggregate {
          count(columns: id)
          avg {
            score
          }
        }
      }
    }
    bakeries_aggregate {
      aggregate {
        count(columns: uid)
      }
    }
  }
`;

function BakeryList() {
  const { loading, error, data } = useQuery(BAKERIES_LIST);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <div className="bakeries_container">
      <div className="bakerylist">
        {data.bakeries.map((bakery, index) => (
          <Bakery key={index} bakery={bakery} />
        ))}
      </div>
    </div>
  );
}

export default withApollo(BakeryList);
